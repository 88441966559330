import { dsl } from '@wix/yoshi-serverless/wrap';

export const getQuizzes = dsl({
          functionName: 'getQuizzes',
          fileName: 'api/quizzes.api',
        });

export const getQuiz = dsl({
          functionName: 'getQuiz',
          fileName: 'api/quizzes.api',
        });