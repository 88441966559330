import { CreateControllerFn } from '@wix/yoshi-flow-editor';
import { getQuiz } from '../../api/quizzes.api';
import { getQuestions } from '../../api/questions.api';

const createController: CreateControllerFn = async ({
  controllerConfig,
  flowAPI,
}) => {
  return {
    async pageReady() {
      const { isClassicEditor, isEditorX } = flowAPI.environment;
      const { location } = controllerConfig.wixCodeApi;
      const quizId =
        isClassicEditor || isEditorX
          ? 'd9c64d10-6b38-4f54-a710-dd631d3b9a19'
          : location.path[1];
      const [quizResponse, questionsResponse] = await Promise.all([
        flowAPI.httpClient.request(getQuiz({ quizId })),
        flowAPI.httpClient.request(getQuestions({ quizId })),
      ]);

      controllerConfig.setProps({
        quiz: quizResponse.data.quiz,
        questions: questionsResponse.data.questions,
      });
    },
  };
};

export default createController;
